.container1 {
    background-image: url(../resources/banner.jpg);

    min-height: 100vh;
    max-height: 135vh;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}
.register-link{
    text-align: center;
}
.bg-login{
    background: url(../resources/login-bg.jpg) no-repeat;
    background-attachment: fixed;
    height: 100vh;
}


.left-bg h1{
    font-size: 26px;
}
.left-bg{
    border: solid 2px #fff;
    margin: 100px auto 0 auto;
    width: 400px;
    height: 470px;
    color: #fff;
}




/* Live Chat Design */

.live-chat{
  width:200px;
  margin: 0 auto;
  border: solid 1px #ccc;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
}
  
.chat-container{
    height: 400px;
  overflow: auto;
  transform: rotate(180deg);
  direction: rtl
}
  
  
.message{
border-bottom: solid 1px #ccc;
padding: 20px;
transform: rotate(180deg);
direction: ltr;
}

.datetime{
    color: #999;
    font-size:10px;
}
    

.send-message-form input{
    width:100%;
    /* border: none; */
    font-size: 16px;
    padding: 10px
}
/* .send-message-form button{
    display:none;
} */
  