.img {
  width: 100vw !important;
  height: 100vh;
}
.home1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.home1_2 {
  color: white !important;
  font-size: 20px;
}

.home_col_1 {
  background-color: rgb(2, 31, 95);
  flex: 0.4;
  padding-left: 20px;
  padding-right: 20px;

  padding-top: 100px;
}
.home_col_2 {
  padding-top: 100px;
  background-image: url("../resources/p2.png") !important;
  background-attachment: cover;
  background-position: center;
  object-fit: cover;
  display: flex;
  flex: 1;
  flex-direction: column;

  width: 100% !important;
}
.FREE {
  width: 100% !important;
  flex: 0.7 !important;
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  color: white;
  font-size: 150px;

  font-weight: 900;
}
.button_signup {
  background-color: red !important;
  color: white;
  border-radius: 20px;
  justify-content: center;
  outline: none;
  border: none;
  padding: 20px;
  font-size: 20px;
  margin-top: 20px;
}
.button_sign {
  background-color: red !important;
  color: white;
  border-radius: 20px;
  justify-content: center;
  outline: none;
  border: none;

  font-size: 20px;
}
.button_signup:hover {
  background-color: rgb(141, 4, 4) !important;
  color: white;
  border-radius: 20px;
  justify-content: center;
  outline: none;
  border: none;
  align-items: center;
}
.Home1 {
  display: flex;
}
@media screen and (max-width: 1117px) {
  .Home1 {
    display: flex;
    flex-direction: column;
  }
}
.Home2 {
  display: flex;

  width: 100% !important;
  height: 100%;
}
@media screen and (max-width: 1117px) {
  .Home2 {
    display: flex;
    flex-direction: column;
  }

  .FREE {
    width: 100% !important;
    flex: 0.7 !important;
    margin-top: 50px;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    color: white;
    font-size: 50px;

    font-weight: 900;
  }
}

.home_col_3 {
  background-color: rgb(2, 31, 95);
  flex: 0.6;
  padding: 20px;

  padding-top: 100px;
}
.home_col_9 {
  padding-top: 100px;
  background-image: url("../resources/ChannelNames.jpg") !important;
  background-attachment: scroll;
  background-size: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex: 0.8;
  flex-direction: column;

  background-color: #e33d3f;
}

.HOME {
  overflow-x: hidden;
  height: 100% !important;
  margin: 0px;
  padding: 0px;
  min-width: 100% !important;
  background-size: contain;
}
.video_a {
  object-fit: contain !important;
  width: 100%;
  height: 300px;
}
.video {
  width: 90%;
  padding: 20px;
}
.heading_3 {
  font-weight: 700;
  color: white;
  font-size: 40px;
}
.bheading_3 {
  font-size: 30px;
  font-weight: 600;
  color: white;
  margin-top: 20px;
}
.body_3 {
  color: white;
  margin-top: 20px;
  font-size: 20px;
}



/* @media screen and (max-width: 384px) {
  #ytplayer {
    width: 384px !important;
    height: 216px !important;
  }
}
@media screen and (max-width: 512px) {
  #ytplayer {
    width: 100% !important;
    height: 288px;
  }
} 
@media screen and (max-width: 640px) {
    
    #ytplayer{
        width:100% !important;
        height:360px}
   
}
@media screen and (max-width: 768px) {
    
    #ytplayer{
        width:100% !important;
        height:432px}
   
}
@media screen and (max-width: 896px) {
    
    #ytplayer{
        width:100% !important;
        height:504px}
   
} */




.thumb{
  font-size: 30px;
  position: absolute;
  bottom: 60px;
}
.thumb2{
  font-size: 30px;
  position: absolute;
  bottom: 0px;
}

.thumb3{
  font-size: 30px;
  position: absolute;
  bottom: -50px;
}
.w-20{
  width: 20%;
  float: left;
  text-align: center;
  
}
.w-20 i {
  font-size: 30px;
  color: #fff;
}
/* //////// */

input.form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: solid 3px #004B84;
  border-radius: 0;
  padding: 50px 20px;
  font-weight: bold;
}

.form-control::placeholder {
  font-size:20px ;
  color: #004B84;
}
.form-control:focus {

  box-shadow: none;
}
.form-section3 p {
  font-size: 20px;
  color: #054D84;
  padding: 20px 50px;
}
h1 {
  font-weight: bold;
  color: #004B84;
}
h5{
  color: #004B84;
}
.form-section3{
  margin-top: 100px;
}

.watch-menu{
  color: #39c5f7;
  
}
.watch-nav-menu{
  color: white;
}




.notification {
  background-color: #555;
  color: white;
  text-decoration: none;
  /* padding: 15px 26px; */
  position: relative;
  display: inline-block;
  border-radius: 2px;
}
.user-notification{
  background-color: #004B84;
  color: white;
  text-decoration: none;
  text-transform: capitalize;
  /* padding: 15px 26px; */
  width: 100%;
  padding: 10px;
  position: relative;
  display: inline-block;
  border-radius: 2px;
}
.notification:hover {
  background: red;
}

.notification .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 10px;
  border-radius: 50%;
  background: red;
  color: white;
}

.user-badge{
  position: absolute;
  top: 10px;
  left: 125px;
  padding: 0px 10px;
  border-radius: 50%;
  background: red;
  color: white;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:relative;
  background: white;
  color: black;
  width: 80%;
  height: 80vh;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-close-button{
  position: absolute;
  right: 0;
  top: -26px;
}

.message-container{
  overflow: auto;
  transform: rotate(180deg);
}

/* .input-container{
  overflow: auto;
  transform: rotate(180deg);
  direction: ltr;
} */


.personal-message-form{
  overflow: auto;
  /* transform: rotate(180deg); */
}

.message-chat{
  /* width:200px; */

  margin: 0 auto;
  border: solid 1px #ccc;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
}

.friend-message{
  padding: 20px;
  color: #fff;
  line-height: 25px;
  border-radius: 5px;
}
.search{
  border-right: 2px solid red;
}


#chat{
	padding-left:0;
	margin:0;
	list-style-type:none;
	overflow-y:scroll;
	height:200px;
	border-top:2px solid #fff;
	border-bottom:2px solid #fff;
}
#chat li{
	padding:10px 30px;
}
#chat h2,#chat h3{
	display:inline-block;
	font-size:13px;
	font-weight:normal;
}
#chat h3{
	color:#bbb;
}
#chat .entete{
	margin-bottom:5px;
}
#chat .private_message{
	padding:20px;
	color:#fff;
	line-height:25px;
	max-width:90%;
	display:inline-block;
	text-align:left;
	border-radius:5px;
}
#chat .me{
	text-align:right;
}
#chat .you .private_message{
	background-color:#58b666;
}
#chat .me .private_message{
	background-color:#6fbced;
}